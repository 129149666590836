// src/App.js

import { Route, Routes, BrowserRouter } from 'react-router-dom';
import LoginForm from './pages/employee-login';
import AllTasksDashboard from './AdminAllTasksDashboard/AdminAllTasksDashboard';
import EmployeeTaskDashboard from './components/EmployeeTaskDashboard';
import AdminTaskDashboardWrapper from './components/Admin';
import SuperAdminLogin from './pages/Admin-login';
import TaskManagementPage from './pages/Home';
import ScrollToTop from './TopScroll';
//import Sidebar from './components/Sidebar'; // If you want the sidebar on all pages
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Bootstrap JS + Popper.js

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        {/* Include Sidebar only for Admin and Employee dashboards */}
        <Routes>
          <Route exact path="/" element={<TaskManagementPage />} />
          <Route exact path="/Admin-Login" element={<SuperAdminLogin />} />
          <Route exact path="/login" element={<LoginForm />} />
          <Route path="/admin-all-tasks" element={<AllTasksDashboard />} />
          <Route path="/admin" element={<AdminTaskDashboardWrapper />} />
          <Route path="/employee" element={<EmployeeTaskDashboard />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
