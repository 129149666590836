import React from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import './index.css';

class TaskPopUpEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            task: this.props.prop[1] ? this.props.prop[1].slice(30, 36) : '',
            employeeID: this.props.prop[0] || '',
            description: this.props.prop[2] || '',
            managerComment: this.props.prop[3] || '',
            attachment: '',
        };
    }

    postUpdateApiTask = async () => {
        const { description, managerComment, attachment } = this.state;
        const postData = {
            idNum: this.props.prop[0],
            taskNum: this.props.prop[1],
            taskDiscription1: description,
            pdfFile1: attachment,
            managerComment1: managerComment
        };

        if (!description && !managerComment) {
            alert('Required: Description or Manager Comment');
            return;
        }

        const url = 'https://unis-task-manager.onrender.com/updateTaskAssigned2';
        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(postData),
        };

        try {
            const response = await fetch(url, options);
            const data = await response.json();
            if (response.status === 201) {
                alert('Task Updated successfully');
                this.props.onHide();
                this.props.onUpdate(data); // Call onUpdate with response data
            } else {
                alert('Error updating task');
            }
        } catch (error) {
            console.error('Fetch error:', error);
            alert('An unexpected error occurred.');
        }
    };

    handleFileChange = (event) => {
        const file = event.target.files[0];
        this.setState({
            attachment: file || '',
        });
    };

    render() {
        const { task, employeeID, description, managerComment } = this.state;

        return (
            <Modal show={this.props.show} onHide={this.props.onHide} centered size="lg" dialogClassName="modal-90w">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formTaskId">
                                    <Form.Label>Task ID</Form.Label>
                                    <Form.Control type="text" value={task} disabled />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formEmployeeId">
                                    <Form.Label>Employee ID</Form.Label>
                                    <Form.Control type="text" value={employeeID} disabled />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group controlId="formDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={description}
                                onChange={(e) => this.setState({ description: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group controlId="formManagerComment">
                            <Form.Label>Manager Comment</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={managerComment}
                                onChange={(e) => this.setState({ managerComment: e.target.value })}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onHide}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={this.postUpdateApiTask}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default TaskPopUpEdit;
