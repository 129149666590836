import React, { Component, Suspense } from "react";
import { Navigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import BackToTop from "../BottomScroll";
import logoutImage from '../assets/images/logout.png';
import assignImage from '../assets/images/assign.png';
import completedImage from '../assets/images/completed.png';
import notCompletedImage from '../assets/images/notCompleted.png';
import deletedImage from '../assets/images/deleted.png';
import allStatusImage from '../assets/images/all.png';
import excelIcon from '../assets/images/excel-icon.png';
import './AdminAllTasksDashboard.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Lazy load the modal component to reduce initial load time
const DashboardTaskPopUp = React.lazy(() => import("../TaskDashboardUpdatePopUp"));

class AllTasksDashboard extends Component {
  state = {
    modalShow8: false,
    taskData: [],
    filter: {
      domain: "All Domains",
      status: "All",
      searchTerm: ""
    },
    error: null,
    isLoading: true,
  };

  componentDidMount() {
    this.fetchTasksData();
  }

  fetchTasksData = async () => {
    const urls = [
      "https://unis-task-manager.onrender.com/getAllTasks",
      "https://unis-task-manager.onrender.com/getDeletedTasks"
    ];

    try {
      const responses = await Promise.all(urls.map(url => fetch(url)));
      const data = await Promise.all(responses.map(res => res.json()));
      this.setState({ taskData: data.flat(), isLoading: false });
    } catch (error) {
      this.setState({ error: "Failed to load tasks", isLoading: false });
      toast.error("Failed to load tasks. Please try again later."); // Error message
    }
  };

  handleFilterChange = (e) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      filter: { ...prevState.filter, [name]: value }
    }));
    toast.info(`Filter updated: ${name} set to ${value}`); // Info message for filter change
  };

  filterTasks = () => {
    const { taskData, filter } = this.state;
    const { domain, status, searchTerm } = filter;

    return taskData.filter(task => {
      const matchesDomain = domain === "All Domains" || task.roleDesgnation === domain;
      const matchesStatus = status === "All" || task.completeStatus === status;
      const matchesSearchTerm = task.employeeName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                task.taskDiscription.toLowerCase().includes(searchTerm.toLowerCase());
      return matchesDomain && matchesStatus && matchesSearchTerm;
    });
  };

  downloadExcel = () => {
    const filteredTasks = this.filterTasks();
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredTasks);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Tasks");
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    saveAs(new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }), "Employee_Tasks.xlsx");
    toast.success("Excel downloaded successfully!"); // Success message on Excel download
  };

  logOutRemoveToken = () => {
    Cookies.remove("Admin_Secret_Token");
    toast.info("Logged out successfully!"); // Info message on logout
    window.location.reload();
  };

  render() {
    const { taskData, filter, error, isLoading } = this.state;
    const filteredTasks = this.filterTasks();
    const token = Cookies.get("Admin_Secret_Token");

    if (!token) {
      return <Navigate to="/" />;
    }

    return (
      <div className="app-container">
        <ToastContainer /> {/* Toast notifications container */}
        
        {/* Top Navbar */}
        <header className="topnav">
          <h1>Admin Task Dashboard</h1>
          <div className="search-wrapper">
            <input
              type="text"
              id="search"
              name="searchTerm"
              value={filter.searchTerm}
              onChange={this.handleFilterChange}
              className="search-input"
              placeholder="Search Employee"
            />
          </div>
          <button className="logout-btn" onClick={this.logOutRemoveToken}>
            <img src={logoutImage} alt="Logout" className="logout-icon" />
          </button>
        </header>

        {/* Sidebar */}
        <aside className="sidenav">
          <Link to="/admin" className="nav-link">
            <img src={assignImage} alt="Assign Task" className="assign-icon" />
            <span className="nav-text">Assign Task</span>
          </Link>
          <div className="status-buttons">
            <button className="status-button" onClick={() => this.setState({ filter: { ...filter, status: "Completed" } })}>
              <img src={completedImage} alt="Completed" className="status-icon" />Completed
            </button>
            <button className="status-button" onClick={() => this.setState({ filter: { ...filter, status: "Not Completed" } })}>
              <img src={notCompletedImage} alt="Not Completed" className="status-icon" />Not Completed
            </button>
            <button className="status-button" onClick={() => this.setState({ filter: { ...filter, status: "Deleted" } })}>
              <img src={deletedImage} alt="Deleted" className="status-icon" />Deleted
            </button>
            <button className="status-button" onClick={() => this.setState({ filter: { ...filter, status: "All" } })}>
              <img src={allStatusImage} alt="All Statuses" className="status-icon" />All Statuses
            </button>
          </div>
        </aside>

        {/* Main Content */}
        <div className="main-content">
          <div className="dashboard-container01">
            <div className="flexHead01">
              <h1 className="dashboard-title2">All Tasks Count: {taskData.length}</h1>
              <div className="dropdown-button-section">
                <select
                  name="domain"
                  value={filter.domain}
                  onChange={this.handleFilterChange}
                  className="select-input"
                >
                  {["All Domains", "FULL STACK", "FRONTEND", "BACKEND", "DATABASE", "BUSINESS ANALYST", "DevOps", "DATA SCIENCE", "TESTING", "UI/UX"].map((domain, idx) => (
                    <option key={idx} value={domain}>{domain}</option>
                  ))}
                </select>
                <button onClick={this.downloadExcel} className="excel-button">
                  <img src={excelIcon} alt="Excel Icon" className="excel-icon" />
                  Download Excel
                </button>
              </div>
            </div>

            {/* Task Table */}
            <h1 className="dashboard-title2">Task Count: {filteredTasks.length}</h1>
            {isLoading ? (
              <div className="loading-container">
                <div className="spinner"></div> {/* Loading spinner */}
              </div>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <div className="employee-task-table">
                <table className="task-table">
                  <thead>
                    <tr>
                      <th>SI Number</th>
                      <th>Employee Name</th>
                      <th>Task ID</th>
                      <th>Description</th>
                      <th>Domain</th>
                      <th>Task Create Time</th>
                      <th>Task Assigned Time</th>
                      <th>Task Completed Time</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <BackToTop />
                  <tbody>
                    {filteredTasks.length > 0 ? filteredTasks.map((task, idx) => (
                      <tr key={task.taskNumber} className={`task-row ${task.completeStatus.toLowerCase().replace(" ", "-")}`}>
                        <td>{idx + 1}</td>
                        <td>{task.employeeName}</td>
                        <td>
                          <Suspense fallback={<div>Loading...</div>}>
                            <span className="addLink" onClick={() => this.setState({ modalShow8: true })}>{task.taskNumber.slice(30, 36)}</span>
                            <DashboardTaskPopUp show={this.state.modalShow8} />
                          </Suspense>
                        </td>
                        <td>{task.taskDiscription}</td>
                        <td>{task.roleDesgnation}</td>
                        <td>{task.taskCreateTime}</td>
                        <td>{task.taskAssignedTime}</td>
                        <td>{task.completeDateTime}</td>
                        <td className={`status ${task.completeStatus.toLowerCase().replace(" ", "-")}`}>{task.completeStatus}</td>
                      </tr>
                    )) : (
                      <tr><td colSpan="9" className="no-results">No employees found</td></tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AllTasksDashboard;
