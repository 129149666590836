import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Navbar, Nav, Container } from 'react-bootstrap';
import Toggle from 'react-toggle';
import 'react-toggle/style.css'; // Import Toggle styles
import './Home.css'; // Import custom CSS
import adminIcon from '../assets/images/admin.png';
import employeeIcon from '../assets/images/employee.png';

const DarkModeToggle = () => {
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    document.body.className = isDark ? 'dark-theme' : 'light-theme';
  }, [isDark]);

  return (
    <Toggle
      checked={isDark}
      onChange={({ target }) => setIsDark(target.checked)}
      icons={{ checked: "🌙", unchecked: "🔆" }}
      aria-label="Dark mode toggle"
      className="theme-toggle"
    />
  );
};

const TaskManagementPage = () => {
  return (
    <div className="task-page-container">
      {/* Navbar Section */}
      <Navbar bg="light" expand="lg" className="shadow-sm">
        <Container>
          <Navbar.Brand href="/">
            <img src="/unis logo.png" alt="Unis Logo" className="logo-img" />
            <span className="brand-name">UNIS TASK MANAGEMENT</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar" />
          <Navbar.Collapse id="navbar">
            <div className="d-lg-none ms-auto">
              <Nav.Link as={Link} to="/Admin-Login" className="nav-login-button">
                <img src={adminIcon} alt="Admin" className="login-icon" />
                Admin Login
              </Nav.Link>
              <Nav.Link as={Link} to="/login" className="nav-login-button">
                <img src={employeeIcon} alt="Employee" className="login-icon" />
                Employee Login
              </Nav.Link>
            </div>
            <Dropdown className="d-none d-lg-block ms-auto">
              <Dropdown.Toggle variant="primary" id="dropdown-basic" className="login-main-button">
                Login
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/Admin-Login">
                  <img src={adminIcon} alt="Admin" className="login-icon" />
                  Admin
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/login">
                  <img src={employeeIcon} alt="Employee" className="login-icon" />
                  Employee
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/* Dark Mode Toggle */}
            <DarkModeToggle />
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Main Header Section */}
      <header className="task-page-header">
        <h1 className="task-page-title">Unis Technosoft Task Management</h1>
        <p className="task-page-subtitle">Manage tasks effectively for Admins and Employees</p>
      </header>

      {/* Footer Section */}
      <footer className="task-footer">
        <p className="footer-text">&copy; 2024 Unis Technosoft. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default TaskManagementPage;
