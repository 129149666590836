import React, { Component } from 'react';
import { Navigate, Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import './employee-login.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isPasswordVisible: false,
    };
  }

  submitForm = async (e) => {
    e.preventDefault();

    const { email, password } = this.state;
    let isValid = true;

    // Validation with toasts for errors
    if (!email) {
      toast.error('Please enter your email address.');
      isValid = false;
    }
    if (!password) {
      toast.error('Please enter your password.');
      isValid = false;
    }

    if (!isValid) return;

    const userDetails = { gmail: email.trim(), employeePassWord: password.trimEnd() };
    const url = "https://unis-task-manager.onrender.com/employeesLoginPost";
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(userDetails),
    });
    const data = await response.json();

    if (response.status === 200) {
      localStorage.setItem("Employee Gmail", data.gmail);
      Cookies.set("Task_Secret_Token", data.jwtToken, { expires: 10 });
      toast.success("Login successful!");
      this.setState({ email: '', password: '' });
    } else if (response.status === 400) {
      toast.error("Incorrect email or password. Please try again.");
      this.setState({ email: '', password: '' });
    }
  }

  onChangeGmail = (event) => {
    this.setState({ email: event.target.value });
  }

  onChangePassword = (event) => {
    this.setState({ password: event.target.value });
  }

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      isPasswordVisible: !prevState.isPasswordVisible,
    }));
  };

  render() {
    const { email, password, isPasswordVisible } = this.state;
    const jwtToken = Cookies.get('Task_Secret_Token');
    if (jwtToken !== undefined) {
      return <Navigate to="/employee" />;
    }
    return (
      <div className="login-form-container">
        <ToastContainer 
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          closeOnClick
          pauseOnHover
          draggable
        />

        <form className="form-container" onSubmit={this.submitForm}>
          <div className="nameCont">
            <h1 className="head2">Unis Employee Login Form</h1>
          </div>
          
          <div className="input-container">
            <label className="input-label" htmlFor="email">
              <FontAwesomeIcon icon={faEnvelope} className="icon" /> EMAIL
            </label>
            <input
              type="text"
              id="email"
              className="username-input-filed"
              value={email}
              onChange={this.onChangeGmail}
            />
          </div>
          
          <div className="input-container password-container">
            <label className="input-label" htmlFor="password">
              <FontAwesomeIcon icon={faLock} className="icon" /> PASSWORD
            </label>
            <div className="password-wrapper">
              <input
                type={isPasswordVisible ? "text" : "password"}
                id="password"
                className="password-input-filed"
                value={password}
                onChange={this.onChangePassword}
              />
              <FontAwesomeIcon
                icon={isPasswordVisible ? faEyeSlash : faEye}
                onClick={this.togglePasswordVisibility}
                className="password-toggle-icon"
                title={isPasswordVisible ? "Hide Password" : "Show Password"}
              />
            </div>
          </div>
          
          <button type="submit" className="login-button">Login</button>
          
          <Link to="/">
            <button type="button" className="task-button admin-button add3">Back To Home</button>
          </Link>
        </form>
      </div>
    );
  }
}

export default LoginForm;
