import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactQuill from 'react-quill';
import "./index.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css'; // Import Quill CSS
import { IoCloseSharp } from "react-icons/io5";

class TaskPostApiPopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addData: [],
            task: '',
            employeeID: this.props.prop[0] || '',
            description: '',
            attachment: '',
            attachmentFormat: '',
            employeeComment: '',
            managerComment: '',
            selectedCreateDate: '',
            selectedAssignedDate: '',
            selectedWorkCompleteDate: '',
            assignedStatus: '',
            completeStatus: 'Not Completed',
        };
    }
 
    handleSubmit = async () => {
        const { description, attachment } = this.state;

        if (!description || !attachment) {
            toast.error("Description and Attachment are required fields");
            return;
        }

        const postData = {
            taskNumber1: "",
            role1: this.props.prop[1],
            employeeName1: this.props.prop[2],
            employeeId1: this.state.employeeID,
            taskDiscription1: description,
            pdfFile1: attachment,
            taskCreateTime1: new Date().toLocaleString(),
            taskAssignedTime1: this.state.selectedAssignedDate,
            assignedStatus1: this.state.assignedStatus,
            completeDateTime1: this.state.selectedWorkCompleteDate,
            completeStatus1: this.state.completeStatus,
            employeeComment1: this.state.employeeComment,
            managerComment1: this.state.managerComment,
        };

        try {
            const response = await fetch("https://unis-task-manager.onrender.com/taskAssignPost", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify(postData),
            });

            if (response.status === 201) {
                const data = await response.json();
                toast.success("Task added successfully");
                this.props.onHide2(data);
                this.setState({
                    description: "",
                    managerComment: "",
                    attachment: "",
                    addData: data
                });
            } else {
                toast.error("Error adding task");
            }
        } catch (error) {
            console.error("Submission error:", error);
            toast.error("An unexpected error occurred.");
        }
    };

    handleFileChange = (event) => {
        const file = event.target.files[0];
        this.setState({
            attachment: file.name || '',
            attachmentFormat: file?.type || ''
        });
    };

    handleDateChange = (type, event) => {
        const indianDate = new Date(event.target.value).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
        this.setState({ [type]: indianDate });
    };

    render() {
        const { description, managerComment, attachment } = this.state;

        return (
            <>
                <ToastContainer />
                <Modal
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header className="setHeaderAl">
                        <Modal.Title id="contained-modal-title-vcenter">
                            POST TASK
                        </Modal.Title>
                        <Button className="BackButton" onClick={this.props.onHide3}>
                            <IoCloseSharp />
                        </Button>
                    </Modal.Header>
                    <Modal.Body className="scrollable-modal-body">
                        <div className="container02">
                            <div className="form-group0423">
                                <label className="lableTaskPOst" htmlFor="employeeID">Employee ID:</label>
                                <input
                                    className="inputPOstTask"
                                    type="text"
                                    id="employeeID"
                                    value={this.props.prop[0]}
                                    disabled // Always disabled
                                />
                            </div>
                            <div className="form-group0523">
                                <label htmlFor="description"><span className="colorRed">*</span> Description:</label>
                                <ReactQuill
                                    value={description}
                                    onChange={(value) => this.setState({ description: value })}
                                    placeholder="Enter the task description here..."
                                    className="rich-text-editor"
                                />
                            </div>
                            <div className="form-group1323">
                                <label htmlFor="managerComment">Manager Comment:</label>
                                <ReactQuill
                                    value={managerComment}
                                    onChange={(value) => this.setState({ managerComment: value })}
                                    placeholder="Enter manager's comment here..."
                                    className="rich-text-editor"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="attachment">Attachment:</label>
                                <input
                                    type="file"
                                    id="attachment"
                                    onChange={this.handleFileChange}
                                />
                                {attachment && <p className="file-info">Selected file: {attachment}</p>}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.props.onHide3} className="cancel-button">Cancel</Button>
                        <Button variant="primary" onClick={this.handleSubmit} className="submit-button">Submit</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default TaskPostApiPopUp;
