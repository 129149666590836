import React, { Component } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import './Admin-login.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class AdminLoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name2: '',
      password3: '',
      isPasswordVisible: false,
    };
  }

  submitFormPost = async (e) => {
    e.preventDefault();
    const { name2, password3 } = this.state;
    const userDetails = { name: name2.trimEnd(), passWord2: password3.trimEnd() };
    const url = 'https://unis-task-manager.onrender.com/superAdminLogin';

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(userDetails),
      });
      const data = await response.json();

      if (response.status === 200) {
        Cookies.set('Admin_Secret_Token', data.jwtToken, { expires: 10 });
        toast.success("Login successful! Redirecting...");
        // Delay navigation to allow the user to see the success toast
        setTimeout(() => this.props.navigate('/admin'), 2000);
      } else if (response.status === 400) {
        toast.error("Invalid Admin Credentials. Please try again.");
        this.setState({ name2: '', password3: '' });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    }
  };

  onChangeName = (event) => {
    this.setState({ name2: event.target.value });
  };

  onChangePassword2 = (event) => {
    this.setState({ password3: event.target.value });
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      isPasswordVisible: !prevState.isPasswordVisible,
    }));
  };

  render() {
    const { name2, password3, isPasswordVisible } = this.state;
    const token = Cookies.get('Admin_Secret_Token');
    if (token !== undefined) {
      return <Navigate to="/admin-all-tasks" />;
    }
    return (
      <div className="login-form-container">
        <ToastContainer 
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          closeOnClick
          pauseOnHover
          draggable
        />

        <form className="form-container" onSubmit={this.submitFormPost}>
          <div className="nameCont">
            <h1 className="head2">Unis Admin Login Form</h1>
          </div>
          <div className="input-container">
            <label className="input-label" htmlFor="username">
              <FontAwesomeIcon icon={faUser} className="icon" /> ADMIN NAME
            </label>
            <input
              type="text"
              id="username"
              className="username-input-filed"
              value={name2}
              onChange={this.onChangeName}
              required
            />
          </div>
          <div className="input-container password-container">
            <label className="input-label" htmlFor="password">
              <FontAwesomeIcon icon={faLock} className="icon" /> PASSWORD
            </label>
            <div className="password-wrapper">
              <input
                type={isPasswordVisible ? 'text' : 'password'}
                id="password"
                className="password-input-filed"
                value={password3}
                onChange={this.onChangePassword2}
                required
              />
              <FontAwesomeIcon
                icon={isPasswordVisible ? faEyeSlash : faEye}
                onClick={this.togglePasswordVisibility}
                className="password-toggle-icon"
                title={isPasswordVisible ? "Hide Password" : "Show Password"}
              />
            </div>
          </div>
          <button type="submit" className="login-button">Login</button>
          <Link to="/">
            <button type="button" className="task-button admin-button add3">Back To Home</button>
          </Link>
        </form>
      </div>
    );
  }
}

const SuperAdminLogin = () => {
  const navigate = useNavigate();
  return <AdminLoginForm navigate={navigate} />;
};

export default SuperAdminLogin;
