import React, { Component } from 'react';
import './Sidebar.css';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      activeDropdown: null,
      selectedDomain: '',
      filterData: [],
      selectedEmployees: [],
      selectEmp: "",
    };
  }

  // Handle search input changes and update state
  handleSearchChange = (e) => {
    this.setState({ searchTerm: e.target.value.toLowerCase() }, this.filterEmployees);
  };

  // Toggle dropdown open/close
  toggleDropdown = (index) => {
    this.setState((prevState) => ({
      activeDropdown: prevState.activeDropdown === index ? null : index,
    }));
  };

  // Select a domain and close the dropdown
  handleSelectDomain = (title) => {
    this.setState({ selectedDomain: title, activeDropdown: null }, this.filterEmployees);
  };

  // Select or deselect an employee
  handleSelectEmployee = (employee, employeeName) => {
    this.setState((prevState) => {
      const { selectedEmployees } = prevState;
      const isSelected = selectedEmployees.includes(employee.employeeName);

      // Update selected employees
      const updatedSelectedEmployees = isSelected
        ? selectedEmployees.filter((emp) => emp !== employee.employeeName)
        : [...selectedEmployees, employee.employeeName];

      // Filter the selected employee data
      const employeeSelectedData = this.props.prop.filter((char) => char.employeeName === employeeName);

      // Set selected employee
      this.setState({ selectEmp: employeeSelectedData });

      // Call parent callback if needed
      this.props.onSelectEmployee(employeeName);

      return { selectedEmployees: updatedSelectedEmployees };
    });
  };

  // Closes dropdown when mouse leaves the sidebar
  handleMouseLeaveSidebar = () => {
    this.setState({ activeDropdown: null });
  };

  // List of domains
  domains = [
    "FULL STACK", "FRONTEND", "BACKEND", "DATABASE", "BUSINESS ANALYST", "DevOps", "DATA SCIENCE", "TESTING", "FIGMA"
  ];

  // Filter employees by selected domain and search term
  filterEmployees = () => {
    const { searchTerm, selectedDomain } = this.state;
    const filteredEmployees = this.props.prop.filter((employee) =>
      (selectedDomain === '' || employee.techStack === selectedDomain) &&
      (searchTerm === '' || employee.employeeName.toLowerCase().includes(searchTerm))
    );
    this.setState({ filterData: filteredEmployees });
  };

  // Trigger filtering after mounting and updates
  componentDidMount() {
    this.filterEmployees();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.searchTerm !== this.state.searchTerm || prevState.selectedDomain !== this.state.selectedDomain) {
      this.filterEmployees();
    }
  }

  render() {
    const { searchTerm, activeDropdown, selectedDomain, filterData, selectedEmployees } = this.state;

    return (
      <div className="animated-sidebar" onMouseLeave={this.handleMouseLeaveSidebar}>
        {/* Domain selection dropdown */}
        <div className="dropdown-container">
          <button className="dropdown-btn" onClick={() => this.toggleDropdown(0)}>
            {selectedDomain || 'Select a domain'}
          </button>
          {activeDropdown === 0 && (
            <ul className="dropdown-content">
              {this.domains.map((domain, index) => (
                <li
                  key={index}
                  onClick={() => this.handleSelectDomain(domain)}
                  className={selectedDomain === domain ? 'domain-selected' : ''}
                >
                  {domain}
                </li>
              ))}
            </ul>
          )}
        </div>
        
        {/* Search bar */}
        <input
          className="search-bar"
          type="text"
          placeholder="Search by employee name"
          value={searchTerm}
          onChange={this.handleSearchChange}
        />

        {/* Employee list */}
        <div className="employee-list">
          {filterData.length > 0 ? (
            filterData.map((employee, index) => (
              <h1
                className={`employee-item ${selectedEmployees.includes(employee.employeeName) ? 'employee-selected' : ''}`}
                key={index}
                onClick={() => this.handleSelectEmployee(employee, employee.employeeName)}
              >
                {employee.employeeName}
              </h1>
            ))
          ) : (
            <p className="no-employee">No employees found</p>
          )}
        </div>
      </div>
    );
  }
}

export default Sidebar;
